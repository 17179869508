/* Container for the entire services list */
.my-services-container {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    /* background-color: var(--rosa-claro) */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Section container */
.my-services-section {}

/* Section title */
.my-services-section-title {
    color: var(--rosa);
    border: 4px solid var(--rosa);
    /* width: 60%; */
    padding: 10px;
    display: inline-block;
}

/* List of services */
.my-services-service-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Individual service item */
.my-services-service-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 200px;
    max-width: 250px;
    /* box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    margin: 15px 0px;
    border: 2px solid var(--rosa-claro);
    border-radius: 20px;
}

/* Service name */
.my-services-service-name {
    font-weight: bold;
    color: #333;
    padding: 10px;
    margin: 0px;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: var(--rosa-claro);
    color: var(--verde);
    font-size: 16px
}

/* Service description */
.my-services-service-description {
    color: #555;
    padding: 15px;
    margin: 0px;
    background-color: white;
    font-size: 14px;
}

/* Service price */
.my-services-service-price {
    font-weight: bold;
    color: #28a745;
}

.my-services-price-button {
    width: 85px;
}

.my-services-price-value {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
    color: var(--verde);
}

.my-services-section-title-container {
    display: flex;
    justify-content: center;
}

.my-services-social-icon {
    height: 45px;
    width: 70px;
}

.my-services-contact-me-social-media-container {
    padding-top: 0px;
    padding-bottom: 15px;
}